import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';

import { SmartLink } from '../SmartLink';

import style from './CaseStudyCard.module.scss';

const CaseStudyCard = ({
    title,
    type,
    image,
    // mobileImage,
    publishingDate,
    cta,
}) => {
    // const isMobile = useMediaQuery('only screen and (max-width: 599px)');
    const hasImage = image && image.url !== '';
    // const formatText = cta.text.toLowerCase();
    return (
        <div className={style.el}>
            <div className={[style.content, !hasImage ? style.noImage : null].join(' ')}>
                {hasImage && (
                    <div className={style.wrapper}>
                        <div className={style.image}>
                            <div className={style.imageWrap}>
                                {/* <img src={isMobile ? mobileImage?.url ? mobileImage.url : image.url : image.url} alt={image.alt} /> */}
                                <img src={image.url} alt={image.alt} />
                            </div>
                        </div>
                    </div>
                )}
                <div className={[style.wrapper, style.right].join(' ')}>
                    <div className={style.header}>
                        <div className={[style.type, !hasImage ? style.orange : ''].join(' ')}>{type}</div>
                        <div className={style.date}>{publishingDate}</div>
                    </div>
                    <div className={style.title}>
                        <div className={style.description}>{title}</div>
                    </div>
                    <div className={style.cta}>
                        <SmartLink href={cta.link.url} hasArrow>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

CaseStudyCard.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.object,
    // mobileImage: PropTypes.object,
    publishingDate: PropTypes.string,
    cta: PropTypes.object,
};

export default CaseStudyCard;
