import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './CarouselStandard.module.scss';
import Slider from 'react-slick';
import { SmartLink } from '../SmartLink';

const CarouselStandard = props => {
    const { title, secondaryText, className, sliderBreakpoints = undefined, sliderParams = undefined, cta, ctaCallback, children } = props;
    if (children.length <= 0) {
        return <></>;
    }

    const sliderEl = useRef(null);
    const [prevDisabled, setPrevDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);

    let activeSliderParams;
    if (sliderParams) {
        activeSliderParams = { ...sliderParams, responsive: [...sliderBreakpoints] };
        activeSliderParams.afterChange = (index) => setArrows(index);
    } else {
        activeSliderParams = {
            afterChange: (index) => setArrows(index)
        };
    }

    const setArrows = (index) => {
        const slidesBreakpoint = sliderBreakpoints?.toReversed().find(br => window.innerWidth < br.breakpoint);
        const slidesPerPage = slidesBreakpoint?.settings.slidesToShow;

        setPrevDisabled(index === 0);
        setNextDisabled(index === children.length - slidesPerPage);
    }

    const slickPrev = () => {
        sliderEl.current.slickPrev();
    }
    const slickNext = () => {
        sliderEl.current.slickNext();
    }

    useEffect(() => {
        setArrows(0);
    }, []);

    return (
        <div className={[style.container, className, 'container'].join(' ')}>
            <div className={style.slickArrows}>
                <button className={`${style.slickArrow} ${style.prevBtn} ${prevDisabled && style.disabled}`} onClick={slickPrev}>
                    <div className='icon-arrow-right' aria-hidden='true' />
                    <div className='sr-only'>previous</div>
                </button>
                <button className={`${style.slickArrow} ${style.nextBtn} ${nextDisabled && style.disabled}`} onClick={slickNext}>
                    <div className='icon-arrow-right' aria-hidden='true' />
                    <div className='sr-only'>next</div>
                </button>
            </div>
            <div className={style.title}>
                <h2>{title}</h2>
            </div>
            {secondaryText !== '' && <div className={style.secondaryText} dangerouslySetInnerHTML={{ __html: secondaryText }}></div>}
            <div className={style.wrapper}>
                <Slider {...activeSliderParams} ref={sliderEl}>
                    {children}
                </Slider>
            </div>
            {cta?.link?.url && (
                <div className={style.cta}>
                    <div className={style.ctaLink}>
                        <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            )}
            {ctaCallback?.onClick && (
                <div className={style.cta}>
                    <div className={style.ctaLink}>
                        <div className={style.ctaCallback} onClick={ctaCallback.onClick}>
                            <a className='arrow-link'>
                                {ctaCallback.text}
                                <span className='icon-arrow-right'></span>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

CarouselStandard.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    secondaryText: PropTypes.string,
    sliderBreakpoints: PropTypes.array,
    sliderParams: PropTypes.array,
    cta: PropTypes.object,
    ctaCallback: PropTypes.object,
    children: PropTypes.array,
};
export default CarouselStandard;
CarouselStandard.defaultProps = {};
