import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'next/image';

import { SmartLink } from '../SmartLink';

import style from './NewsCard.module.scss';

const NewsCard = props => {
    const { type, publishingDate, title, image, cta, tags } = props;

    const hasImage = image && image.url !== '';

    const formatText = cta.text.toLowerCase();
    return (
        <div className={[style.el, hasImage ? '' : style.noImage].join(' ')}>
            {hasImage && (
                <div className={style.image}>
                    <img
                        src={image?.url}
                        alt={image?.alt}
                        // layout='fill'
                    />
                </div>
            )}
            <div className={style.content}>
                <div className={style.tag}>
                    <div className={style.tagBox}>{type}</div>
                    <div className={style.date}>{publishingDate}</div>
                </div>
                <div className={style.title}>
                    <h4>{title}</h4>
                </div>
                <div className={style.cta}>
                    <SmartLink
                        href={cta.link.url}
                        // hasArrow
                        className='arrow-link'
                    >
                        {cta.text}{' '}
                        {formatText === 'download' ? (
                            <span className='icon-download' />
                        ) : (
                            <span className='icon-arrow-right' />
                        )}
                    </SmartLink>
                </div>
                <div className={style.tags}>
                    {tags &&
                        tags.map((item, i) => (
                            <div className={style.tag} key={i}>
                                {item}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

NewsCard.propTypes = {
    type: PropTypes.string,
    publishingDate: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    tags: PropTypes.array,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.shape({
            target: PropTypes.string,
            url: PropTypes.string,
        }),
    }),
};

export default NewsCard;
