import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '@react-hook/window-size/throttled';

import NewsCard from './NewsCard';
import ReportCard from '../Cards/reportCard';
import CaseStudyCard from '../Cards/caseStudyCard';

import style from './RelatedNews.module.scss';
import { chunk } from 'lodash';
import CarouselStandard from '../CarouselStandard';

const RelatedNews = props => {
    const { title, description, cta, cardType } = props;
    let { items } = props;
    if (items.length <= 0) {
        return <></>;
    }

    const sortDate = (a, b) => {
        return new Date(b.publishingDate) - new Date(a.publishingDate);
    };
    items = items.sort(sortDate);

    const sliderParams = {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
    };

    const sliderBreakpoints = [
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ]

    const wSize = useWindowSize();
    let renderSingleCard = false;
    if (wSize && wSize[0] < 768) {
        renderSingleCard = true;
    }

    useEffect(() => {
        if (wSize && wSize[0] < 768) {
            renderSingleCard = true;
        } else {
            renderSingleCard = false;
        }
    }, [wSize]);

    const renderCard = (data, index) => {
        switch (cardType) {
            case 'report':
                return (
                    <div key={index} className={style.reportCard}>
                        <ReportCard {...data} />
                    </div>
                );
            case 'caseStudy':
                return (
                    <div key={index} className={style.reportCard}>
                        <CaseStudyCard {...data} />
                    </div>
                );
            default:
                return <NewsCard key={index} {...data} />;
        }
    };

    return (
        <div className={[style.el, 'related-news'].join(' ')}>
            {renderSingleCard && (
                <CarouselStandard className={style.carouselOverrides} title={title} secondaryText={description} cta={cta} sliderParams={sliderParams} sliderBreakpoints={sliderBreakpoints}>
                    {items.map((item, i) => <Fragment key={i}>{renderCard(item, i)}</Fragment>)}
                </CarouselStandard>
            )}
            {!renderSingleCard && (
                <CarouselStandard className={style.carouselOverrides} title={title} secondaryText={description} cta={cta} sliderParams={sliderParams} sliderBreakpoints={sliderBreakpoints}>
                    {chunk(items, 4).map((chunk, i) => (
                        <div key={i} className={style.flexView}>{chunk.map((item, index) => renderCard(item, index))}</div>
                    ))}
                </CarouselStandard>
            )}
        </div>
    );
};

RelatedNews.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
    cardType: PropTypes.string,
};

export default RelatedNews;
